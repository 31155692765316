.container-alert {
  display: flex;
  flex-direction: column;
  justify-content: 'center';
  align-items: center;
  row-gap: 10px;
}

.alert-primary-custom {
  background-color: #E3F4FF;
  color: #084298;
  border-color: #1B78B3;
  max-width: 95%;
}

.alert-icon-container {
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}

.alert-description-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  row-gap: 10px;
}

.alert-description {
  color: #333;
  font-weight: 54;
}
