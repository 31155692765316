@media screen {
  .cabecalho {
    display: none;
  }
}

@page {
  size: 80mm;
  margin: 0mm 0mm 0mm 0mm;
}

.cabecalho p {
  font-size: 5vw;
  color: black;
}

.pac-container {
  z-index: 1100 !important;
}